/*
How to toggle a css class (ugly-ish, less-ugly-ish)  
https://devdojo.com/krissanawat101/3-ways-for-toggle-a-class-in-react 
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  margin: auto;
  width: 70%;
}

/* Add a black background color to the top navigation */
.navbar {
  background-color: rgba(226, 230, 22, 0.746);
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.navbar a {
  float: left;
  color: #4f501e;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 19px;
  font-family: "Papyrus", "Monaco", monospace;
  font-weight: bold;
  
}

/* Change the color of links on hover */
.navbar a:hover {
  background-color: #ddd;
  color: black;
}

/* Add a color to the active/current link */
.navbar a.active {
  background-color: #4CAF50;
  color: white;
}

/* .styled-table: https://dev.to/dcodeyt/creating-beautiful-html-tables-with-css-428l */
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* .styled-table: https://dev.to/dcodeyt/creating-beautiful-html-tables-with-css-428l */